import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n"
import { IntlProvider } from "react-intl"
import "intl"
import Layout from "../components/Layout"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import { Col, Container, Row } from "react-bootstrap"
import Quote from "../../static/svg/life-at-jfe-icon.svg"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Heading24, Heading48, Paragraph18 } from "../styles/PageStyles"

const LifeAtJfe = ({ data, location, i18nMessages }) => {
  const url = location.pathname
  const { langs, defaultLangKey } = data.site.siteMetadata.languages
  const langKey = getCurrentLangKey(langs, defaultLangKey, url)
  const homeLink = `/${langKey}/`
  const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url))

  const testimonials = data.allMarkdownRemark.nodes

  return (
    <IntlProvider locale={langKey} messages={i18nMessages}>
      <Layout seoTitle="Life at JFEEI - Testimonials">
        <Navbar langKey={langKey} langs={langsMenu} url={url} />
        <StyledContainer fluid>
          <Heading48>Life at JFE Engineering Indonesia</Heading48>
          {testimonials.map((testimonial, index) => (
            <StyledRow id={`testimonial-${index + 1}`} key={index}>
              <StyledCol className="testimonial-profile" lg={3}>
                <div className="testimonial-img">
                  <Quote />
                  <GatsbyImage
                    image={getImage(testimonial.frontmatter.image)}
                    alt={testimonial.frontmatter.title}
                  />
                </div>
                <div className="name">
                  <Heading24>{testimonial.frontmatter.title}</Heading24>
                </div>
                <div className="job-position">
                  <Paragraph18>{testimonial.frontmatter.jobTitle}</Paragraph18>
                </div>
              </StyledCol>
              <StyledCol lg={9}>
                <div className="testimonial-desc">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: testimonial.html,
                    }}
                  />
                </div>
              </StyledCol>
            </StyledRow>
          ))}
        </StyledContainer>
        <Footer langKey={langKey} />
      </Layout>
    </IntlProvider>
  )
}

export default LifeAtJfe

const StyledContainer = styled(Container)`
  padding: 165px 10% 75px 10%;

  h1 {
    margin-bottom: 50px;
    text-align: center;
  }

  p {
    margin-bottom: 25px;
  }
`

const StyledRow = styled(Row)`
  display: flex;
  &:nth-child(odd) {
    flex-direction: row-reverse;

    .testimonial-profile {
      align-items: flex-end;
    }

    .testimonial-img {
      justify-content: flex-end;
    }
  }

  @media only screen and (min-width: 992px) {
    &:nth-child(odd) {
      flex-direction: row-reverse !important;
    }
  }

  @media only screen and (max-width: 992px) {
    &:nth-child(odd) {
      flex-direction: row;

      .testimonial-profile {
        align-items: center;
      }

      .testimonial-img {
        justify-content: center;
      }
    }
  }
`

const StyledCol = styled(Col)`
  position: relative;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  svg {
    position: absolute;
    top: 0;
  }

  .testimonial-img {
    border-radius: 50%;
    overflow: hidden;
    margin-top: 50px;
    max-width: 270px;
    max-height: 270px;

    @media only screen and (max-width: 991px) {
      width: 270px;
      height: 270px;
    }
  }

  .name,
  .job-position {
    width: 100%;

    h1,
    p {
      text-align: center;
    }
  }

  .name {
    margin-top: 15px;
    h1 {
      margin: 0;
    }
  }

  .job-position {
    p {
      font-weight: bold;
      color: #8f9496;
    }
  }

  .testimonial-desc {
    p {
      line-height: 20px;
      text-align: justify;
      letter-spacing: 0.06em;
      text-indent: 3em;
    }
  }

  @media only screen and (min-width: 1366px) and (max-width: 1440px) {
    .testimonial-desc {
      p {
        font-size: 14px;
      }
    }
  }

  @media only screen and (max-width: 992px) {
    align-items: center;
    margin-bottom: 25px;
    h1 {
      text-align: left;
    }
  }
`

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "testimonials" } } }
    ) {
      nodes {
        html
        frontmatter {
          title
          jobTitle
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`
